define('env',['module', 'browserUtils'
], function (module, BrowserUtils) {
  'use strict';

  var Env = {
    config: {
      defaults: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '872216205306-sao1eokcksf6127esldba7rqhjfbib15.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAAj28bmWED0Mz2Th0653_cW-3g-BhiKcI',
        editorUrl: 'http://localhost:4200'
      },
      development: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '872216205306-sao1eokcksf6127esldba7rqhjfbib15.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAAj28bmWED0Mz2Th0653_cW-3g-BhiKcI',
        editorUrl: 'http://localhost:4200'
      },
      devserver: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '872216205306-sao1eokcksf6127esldba7rqhjfbib15.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyAAj28bmWED0Mz2Th0653_cW-3g-BhiKcI',
        editorUrl: 'http://tn.dev-server:4200',
        compuzzEnv: 'compacon'
      },
      minified: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'images/compuzz_logo.png',
        compuzzLogo: 'images/compuzz.svg',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      alpha: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://alphadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/alpha/',
        editOrderUrl: 'https://alpha.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      beta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://betadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli',
        gmailClientId: '',
        gmailAPIKey: '',
        editorUrl: 'https://betaeditor.compuzz.com'
      },
      igobeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://igobetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: '',
        compuzzEnv: 'igo',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      igo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://igodashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://igodashboard.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: 'cpzigo',
        compuzzEnv: 'igo',
        nodeUrl: 'https://compulogo.compuzz.com/',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      compaconbeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://compaconbetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: 'cpzcpc',
        compuzzEnv: 'compacon',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      compacon: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://compacondashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://compacondashboard.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: 'cpzcpc',
        compuzzEnv: 'compacon',
        nodeUrl: 'https://compulogo.compuzz.com/',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      adtv2: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://dashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      prod2: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://dashboard2.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      zaprinta: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        downloadUrl: 'https://x3wn3zu7te.execute-api.eu-west-1.amazonaws.com/prod/dl/',
        filesPath: 'files/',
        serverUrl: 'https://zaprinta.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'zaprinta',
        gmailClientId: '1044157018081-qibpa2m59bintgd2j1gjk7ha6puogk2t.apps.googleusercontent.com',
        gmailAPIKey: 'AIzaSyC4NZnsWK8joARK8bVTNch-G7rQYJw_vC4',
        nodeUrl: 'https://ncf.compuzz.com/',
        webshopUrl: 'https://www.zaprinta.com'
      },
      lapierre: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://lapierre.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'lapierre',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      prestigelogo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://prestigelogo.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'prestigelogo',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      promoxl: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://promoxl.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'promoxl',
        gmailClientId: '',
        gmailAPIKey: ''
      },
      brandbutler: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://brandbutler.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'brandbutler',
        gmailClientId: '',
        gmailAPIKey: ''
      }
    },

    isProduction: function () {
      return this.getEnvironment() === 'production';
    },

    getEnvironment: function () {
      switch (BrowserUtils.getHostName()) {
        case 'localhost':
        case '127.0.0.1':
          return 'localhost';
        case 'alphadashboard.compuzz.com':
          return 'alpha';
        case 'betadashboard.compuzz.com':
          return 'beta';
        case 'dashboard.compuzz.com':
          return 'production';
      }
    }
  };
  module.exports = Env;
});

